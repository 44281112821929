body {
  margin: 0;
  overflow: hidden;
  background-color: #0F1014;
}

.container-btns {
  margin-left: 4vw;
  width:80%;
  position:absolute;
  display: flex;
  justify-content: start;
}
.container-btns > button {
  margin-right: 3vw;
  border: 0;
  padding: 0;
  outline: none;
}
.container-btns > button > img{
  height: 280px;
  width: 200px;
  object-fit: cover;
}

.whole-container{
  position: fixed;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

/* ANIMATIONS */
.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms, transform 500ms;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms, transform 500ms;
}
/* CAROUSEL */
.slide-img{
  object-fit: contain;
  height: 100%;
}
.swiper-pagination {
  position: fixed;
  top: 2vh;
  width: auto;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  left: 0;
  right: 0;
}
.swiper-pagination > .swiper-pagination-bullet{
  height: 2vw;
  width: 2vw;
  margin: 0 12.5px;
  background-color: white;
  opacity: 0.5;
  border-radius: 50%;
  display: block;
}
.swiper-pagination > .swiper-pagination-bullet.swiper-pagination-bullet-active{
  opacity:1;
}
/* Image */
.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0; /* Colore di sfondo per il placeholder */
}

/* VIDEO */
.fullscreen-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(30px);
  z-index: 1;
}

.foreground-image {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.foreground-image img {
  width: 100%;
  height: 80%;
  object-fit: contain;
}
